import { useSelector } from "react-redux";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import "./style.css";
import Table from "../../components/table";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function Invoice() {
  const language = useSelector((state) => state.language);
  const userData = localStorage.getItem("logAuth")
    ? JSON.parse(localStorage.getItem("logAuth"))
    : null;
  const [fatoora, setFatoora] = useState([]);
  const headers = [
    {
      label: "#",
      dataIndex: "",
    },
    {
      label: language == "ar" ? "اسم المنتج" : "Product Name",
      dataIndex: "product_name",
    },
    {
      label: language == "ar" ? "اسم المستخدم" : "User Name",
      dataIndex: "user_name",
    },
    {
      label: language == "ar" ? "رقم الفاتورة" : "Invoice Number",
      dataIndex: "fatoorah_id",
    },
    {
      label: language === "ar" ? "تاريخ الانشاء" : "Created At",
      dataIndex: "agent_email",
      type: "children",
      children: ({ row }) => <p>{row?.created_at}</p>,
    },
    {
      label: language == "ar" ? "رقم الشخص" : "Phone Number",
      dataIndex: "user_phone",
    },
    {
      label: language == "ar" ? "المبلغ" : "Amount",
      dataIndex: "price",
      type: "children",
      children: ({ headers, row }) => {
        return <span>{parseFloat(row?.price)}</span>;
      },
    },
    {
      label: language == "ar" ? "المبلغ الكلي" : "Total Amount",
      dataIndex: "submission_price",
      type: "children",
      children: ({ headers, row }) => {
        return <span>{parseFloat(row?.submission_price)}</span>;
      },
    },
    {
      label: language == "ar" ? "مصاريف إدارية" : "Administartive expenses",
      dataIndex: "administrative_expenses",
      type: "children",
      children: ({ headers, row }) => {
        return (
          <span>
            {(
              +row?.submission_price *
              (+row?.administrative_expenses / 100)
            ).toFixed(1)}
          </span>
        );
      },
    },
  ];

  function handleGetAllData() {
    axios
      .post(
        `https://baseeta-form.com/basita/getAgentFatoorah.php?agent_id=${userData?.agent_id}`
      )
      .then((res) => {
        if (res.data) {
          setFatoora(res.data);
          // toast.success("");
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "There's a problem");
        }
      });
  }

  useEffect(() => {
    handleGetAllData();
  }, []);

  return (
    <div className="h-container con-h">
      <DefaultLayout>
        <div className="childs">
          <div className="header">
            <ContentNav head={language == "ar" ? "فواتيرك" : "Your Invoices"} />
          </div>

          <Table headers={headers} body={fatoora} />
        </div>
      </DefaultLayout>
    </div>
  );
}
